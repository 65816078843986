import React from 'react';

import { connect } from 'react-redux'
import { compose } from 'redux'

import { makeStyles } from '@material-ui/core';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon
} from 'react-share'

const useStyles = makeStyles(theme => ({
    socialview: {
        display: "flex",
        margin: theme.spacing(1),
        position: "fixed",
        bottom: "0%",
        right: "0%",
        zIndex: 100,
    },
    socialbutton: {
        display: "inline-block",
        marginRight: "0.1em",
        opacity: "50%",
        "&:hover": {
            opacity: "100%",
        },
    },
}));

const mapStateToProps = state => { return {} }
const mapDispatchToProps = dispatch => { return {} }

const config = {
    via: 'kenkawakenkenke',
    size: 48,
}

const Component = ({
    url, title
}) => {
    const classes = useStyles();
    return (<div className={classes.socialview}>
        <FacebookShareButton url={url} className={classes.socialbutton}>
            <FacebookIcon size={config.size} round />
        </FacebookShareButton>
        <TwitterShareButton url={url} title={title} via={config.via} className={classes.socialbutton}>
            <TwitterIcon size={config.size} round />
        </TwitterShareButton>
    </div>);
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps)
)(Component);
