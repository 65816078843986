import React from 'react';

import { StationMap, POIMap } from '../data/data.js';

import { connect } from 'react-redux'
import { compose } from 'redux'

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

// const StationsDBFlat = StationsDB.map(station => station.station_name);
// const POIMap = POIsDB.reduce((map, e) => { map[e.i] = e; return map; }, {});

const useStyles = makeStyles(theme => ({
    root: {
        // flexGrow: 1,
    },
    contents: {
        // margin: theme.spacing(1),
    },
    title: {
        // flexGrow: 1,
    },
    pathView: {
        // backgroundColor: 'lightgray',
        paddingLeft: '0.4em',
    },
    poiBlock: {
        position: "relative",
        // backgroundColor: '#ffdddd',
        // display: "inline-block",
        display: "table-row",

        // border: "solid 1px red",
        // lineHeight: '3em',
    },
    pathLineBlock: {
        // display: "inline-block",
        position: "relative",
        // display: "inline-block",
        display: 'table-cell',
        // backgroundColor: "lightgray",
        // border: "solid 1px green",
        // height: "0%",
        // border: "solid",
        // width: "100%",
        // height: "100%",
    },
    circle: {
        position: "relative",
        // position: "absolute",
        zIndex: 1,
        width: "1.5em",
        height: "1.5em",
        marginTop: '0.5em',
        marginBottom: '0.5em',

        // Ensure it's horizontally centered.
        margin: "auto",
        left: 0,
        right: 0,

        // marginLeft: '0.5em',
        // marginRight: '0.5em',
        // lineHeight: '10em',
        lineHeight: "normal",
        // textAlign: "center",
        borderRadius: "50%",
        border: 'solid 3px skyblue',
        backgroundColor: 'white',
        // backgroundColor: '#ddddff',
    },
    majorCircle: {
        backgroundColor: 'white',
    },
    minorCircle: {
        backgroundColor: 'white',
    },
    emojiCircle: {
        position: "relative",
        zIndex: 1,
        width: "1.5em",
        height: "1.5em",
        marginTop: '0.5em',
        marginBottom: '0.5em',
        lineHeight: "normal",
        textAlign: 'center',
        // textAlign: "top",
        borderRadius: "50%",
        border: 'solid 3px skyblue',
        backgroundColor: '#ddddff',

        // fontSize: '0.6em',
        // overflow: "hidden",
        // textOverflow: "ellipsis",
        // whiteSpace: "nowrap",
    },

    line: {
        position: "absolute",
        zIndex: 0,
        width: "0.15em",
        height: "100%",
        top: 0,

        // Ensure it's horizontally centered.
        margin: "auto",
        left: 0,
        right: 0,

        // borderLeft: 'solid 0.1em gray',
        // borderRight: 'solid 0.1em gray',
    },
    startLine: {
        top: 0,
        height: "50%",
    },
    endLine: {
        top: "50%",
        height: "50%",
    },
    trainLine: {
        borderLeft: 'solid 0.1em gray',
        borderRight: 'solid 0.1em gray',
    },
    walkLine: {
        width: "0em",
        borderLeft: 'solid 0.1em lightgray',
        borderRight: 'solid 0.1em lightgray',
    },
    noLine: {
        borderLeft: 'none',
        borderRight: 'none',
    },
    connectLine: {
        top: 0,
        height: "100%",
    },
    textLabel: {
        position: "relative",
        display: 'table-cell',
        lineHeight: "normal",
        verticalAlign: 'middle',
        paddingLeft: '0.3em',
    },
    poiLabel: {
        fontSize: '1.2em',
    },
    lineLabel: {
        fontSize: '0.8em',
        color: 'gray',
        paddingLeft: '1.2em',
    },
}));

const mapStateToProps = state => { return {} }
const mapDispatchToProps = dispatch => { return {} }

function LineClass(classes, style) {
    switch (style) {
        case 'train':
            return classes.trainLine;
        case 'walk':
            return classes.walkLine;
        default:
            return classes.noLine;
    }
}

function PoiBlock(classes, keyBase, label, circleStyle, topLineStyle, bottomLineStyle) {
    let topLineClass = LineClass(classes, topLineStyle);
    let bottomLineClass = LineClass(classes, bottomLineStyle);
    let labelClass = circleStyle === 'none' ? classes.lineLabel : classes.poiLabel;
    return (
        <div key={keyBase + label} className={classes.poiBlock}>
            <div className={classes.pathLineBlock}>
                {circleStyle === 'major' && <div className={clsx(classes.circle, classes.majorCircle)}></div>}
                {circleStyle === 'work' && <div className={clsx(classes.emojiCircle)}><span role="img" aria-label="会社">🏢</span></div>}
                {circleStyle === 'home' && <div className={clsx(classes.emojiCircle)}><span role="img" aria-label="家">🏠</span></div>}
                {circleStyle === 'poi' && <div className={clsx(classes.emojiCircle)}><span role="img" aria-label="景勝地">🌴</span></div>}
                <div className={clsx(classes.line, classes.startLine, topLineClass)}></div>
                <div className={clsx(classes.line, classes.endLine, bottomLineClass)}></div>
            </div>
            {/* <div className={clsx(classes.textLabel, labelClass)}>{label}</div> */}
            <Typography variant="body2" className={clsx(classes.textLabel, labelClass)}>
                {label}
            </Typography>
        </div >
    );
}

const Component = ({ keyBase, pathResult, workStation }) => {
    const classes = useStyles();
    if (!pathResult || !workStation) {
        return ("");
    }
    const poi = POIMap[pathResult.poi];

    var blocks = [];
    for (let i = 0; i < pathResult.path.length; i++) {
        let blockKeyBase = keyBase + "_" + i;
        let path = pathResult.path[i];
        let segmentFrom = StationMap[path.f];
        let segmentTo = StationMap[path.t];

        const isFirst = i === 0;
        const isLast = i === pathResult.path.length - 1;
        const segmentToIsWork = path.w && segmentTo.i === workStation.i;

        // First
        if (isFirst) {
            blocks.push(PoiBlock(classes,
                blockKeyBase,
                segmentFrom.n + " で乗る",
                /* circleStyle= */ 'home',
                isFirst ? "none" : "walk",
                "train"
            ));
        }

        // Line
        blocks.push(PoiBlock(classes,
            blockKeyBase,
            segmentFrom.l,
            /* circleStyle= */ 'none',
            "train",
            "train"));
        if (path.w && !segmentToIsWork) {
            blocks.push(PoiBlock(classes,
                blockKeyBase,
                "" + workStation.n + " を通り過ぎる",
                /* circleStyle= */ 'work',
                "train",
                "train"));
        }

        // Last
        if (isLast) {
            blocks.push(PoiBlock(classes,
                blockKeyBase,
                segmentTo.n + " で降りる",
                /* circleStyle= */ 'major',
                "train",
                "walk"));
        } else if (segmentToIsWork) {
            blocks.push(PoiBlock(classes,
                blockKeyBase,
                segmentTo.n + " で会社に行かずに乗り換える",
                /* circleStyle= */ 'work',
                "train",
                "train"));
        } else {
            blocks.push(PoiBlock(classes,
                blockKeyBase,
                segmentTo.n + " で乗り換える",
                /* circleStyle= */ 'major',
                "train",
                "train"));
        }
    }
    blocks.push(
        PoiBlock(classes,
            keyBase + "_walk",
            poi.n + " まで歩く",
                /* circleStyle= */ 'poi',
            "walk",
            "none"));
    return (
        <div className={classes.pathView}>
            {blocks}
        </div >);
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps)
)(Component);
