import React, { useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";

import StationSuggest from './stationsuggest.js';
import PathsPage from './pathspage';
import SocialView from './socialview.js';

import { StationsDB } from '../data/stations.js';
import firebase from '../firebase/';

import { fade, makeStyles } from '@material-ui/core/styles';
import { Link, AppBar, Toolbar, Typography } from '@material-ui/core';

import { connect } from 'react-redux'
import { compose } from 'redux'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    contents: {
        margin: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
    },
    textField: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },
    searchField: {
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    toolbar: {
        // minHeight: 128,
        alignItems: 'flex-start',
        flexDirection: 'column',
        background: "#FCFCFC",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    pageTitle: {
        color: "#404040",
    }
}));

const mapStateToProps = state => {
    return {
    };
}
const mapDispatchToProps = dispatch => {
    return {
        // doLogout: bindLogout(dispatch),
    }
}

const Component = ({
    match
}) => {
    const classes = useStyles();
    const history = useHistory();

    const initialFrom = match.params.inFrom ? StationsDB.filter(station => station.i === match.params.inFrom)[0] : null;
    const initialTo = match.params.inTo ? StationsDB.filter(station => station.i === match.params.inTo)[0] : null;

    const [fromStation, setFromStation] = useState(initialFrom);
    const [toStation, setToStation] = useState(initialTo);

    useEffect(() => {
        const url =
            "/"
            + (fromStation ? fromStation.i : "_")
            + "/"
            + (toStation ? toStation.i : "_");
        if (fromStation && toStation) {
            firebase.analytics().logEvent("search", { value: fromStation.i + "_" + toStation.i });
        }
        history.push(url);
    }, [fromStation, toStation, history]);

    function loggedSetFromStation(s) {
        if (s) {
            firebase.analytics().logEvent("set_from_station", { station: s.i, value: s.i });
        } else {
            firebase.analytics().logEvent("clear_from_station");
        }
        setFromStation(s);
    }
    function loggedSetToStation(s) {
        if (s) {
            firebase.analytics().logEvent("set_to_station", { station: s.i, value: s.i });
        } else {
            firebase.analytics().logEvent("clear_to_station");
        }
        setToStation(s);
    }

    return (
        <div>
            <AppBar position="static">
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h3" className={classes.pageTitle}>
                        <Link href="/" onClick={e => firebase.analytics().logEvent("click_page_title")} color="inherit">
                            乗り過ごし検索
                        </Link>
                    </Typography>
                    <Typography variant="subtitle1" className={classes.pageTitle}>
                        会社を通り過ぎて、良い景色を見に行こう。
                    </Typography>
                    <StationSuggest onSet={loggedSetFromStation} title="出発駅🏠" currentStation={fromStation} />
                    <StationSuggest onSet={loggedSetToStation} title="目的駅🏢" currentStation={toStation} />
                </Toolbar>
            </AppBar>
            <PathsPage fromStation={fromStation} toStation={toStation} />
            <SocialView url={"https://search.skip.work/"} title="乗り過ごし検索" />
        </div >
    )
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps))(Component);
