import React, { Fragment } from 'react';
import './App.css';

import MainPage from './component/mainpage.js';
import DebugView from './component/debug.js';

import firebase from './firebase/';
import { Provider } from 'react-redux'
import store from './models/store.js';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore' // <- needed if using firestore
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const rrfProps = {
  firebase,
  config: {
    // userProfile: 'users',
    // useFirestoreForProfile: true
  },
  dispatch: store.dispatch,
  createFirestoreInstance
}

function App() {
  return (
    <Fragment>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <Router>
            <Switch>
              {/* <Route exact path='/about' component={About} /> */}
              <Route path='/debug' component={DebugView} />
              <Route path='/:inFrom?/:inTo?' component={MainPage} />
              <Route path='/' component={MainPage} />
            </Switch>
          </Router>
        </ReactReduxFirebaseProvider>

      </Provider>
    </Fragment  >
  );
}

export default App;
