import React from 'react';

import PathView from './pathview.js';

import { connect } from 'react-redux'
import { compose } from 'redux'

import {
    makeStyles,
    Typography,
    Paper,
    Grid
} from '@material-ui/core';

import { firestoreConnect } from 'react-redux-firebase'
import { POIMap } from '../data/data.js';

// import AdSense from 'react-adsense';
import firebase from '../firebase/';

// const StationsDBFlat = StationsDB.map(station => station.station_name);
// const POIMap = POIsDB.reduce((map, e) => { map[e.i] = e; return map; }, {});
// const StationMap = StationsDB.reduce((map, e) => { map[e.i] = e; return map; }, {});

const useStyles = makeStyles(theme => ({
    root: {
        // flexGrow: 1,
        padding: "1em",
        margin: "0.4em",
        marginTop: "1.2em",
    },
    contents: {
        margin: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
    },
    poiImage: {
        width: "100%",
    },
    licenseText: {
        color: "#555555",
    }
}));

const mapStateToProps = state => {
    return {
        // uid: state.firebase.auth.uid,

        // pathsResult: state.firestore.ordered.pathsResult && state.firestore.ordered.pathsResult.length > 0
        //     ? state.firestore.ordered.pathsResult[0]
        //     : {},
    };
}
const mapDispatchToProps = dispatch => {
    return {
        // doLogout: bindLogout(dispatch),
    }
}

const Component = ({
    pageIdx,
    fromStation,
    toStation,
    pathResult
}) => {
    const classes = useStyles();
    if (!pathResult || !fromStation || !toStation) {
        return ("");
    }
    if (pathResult.path.length === 0) {
        return ("No path!");
    }
    const poi = POIMap[pathResult.poi];

    return (
        <Paper elevation={2} variant="outlined" className={classes.root}>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <Typography variant="h6">
                        会社を通り過ぎて{Math.floor(pathResult.t)}分で
                        <a href={poi.u}
                            onClick={
                                e => { firebase.analytics().logEvent("click_poi_title", { value: pathResult.poi }); }
                            }
                            target='_blank' rel="noopener noreferrer">
                            {poi.n}
                        </a>
                    </Typography>
                </Grid>

                <Grid item sm={4} xs={12}>
                    <PathView keyBase={pageIdx} pathResult={pathResult} workStation={toStation} />
                </Grid>

                <Grid item sm={8} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                            <a href={poi.h.p}
                                onClick={e => {
                                    firebase.analytics().logEvent("click_poi_image", { value: pathResult.poi })
                                }
                                }
                                target='_blank' rel="noopener noreferrer">
                                <img
                                    src={`${process.env.PUBLIC_URL}` + poi.h.u}
                                    alt={poi.n}
                                    className={classes.poiImage}
                                />
                            </a>
                            {poi.h.l &&
                                <Typography variant="caption" className={classes.licenseText}>
                                    {poi.h.l.d}
                                </Typography>
                            }
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Typography variant="body2" >
                                {poi.d}
                            </Typography>
                            <Typography variant="overline" >
                                <a
                                    href={poi.u}
                                    onClick={e => firebase.analytics().logEvent("click_poi_wiki", { value: pathResult.poi })}
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Wikipedia
                                </a> <a
                                    href={"https://www.google.com/maps/search/?api=1&query=" + poi.c}
                                    onClick={e => {
                                        firebase.analytics().logEvent("click_poi_maps", { value: pathResult.poi });
                                    }}
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Googleマップで開く</a>
                            </Typography>

                            {/* Google ads */}
                            {/* <Card key="adBottomDetail">
                                <AdSense.Google
                                    client={"ca-pub-5446819664573115"}
                                    slot={"5751675853"}
                                    style={{ display: "block" }}
                                    format='auto'
                                    responsive='true'
                                    layoutKey='-gw-1+2a-9x+5c'
                                />
                            </Card> */}

                        </Grid>
                    </Grid>
                </Grid>
                {/* </Box> */}
            </Grid>
        </Paper >);
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps),
    firestoreConnect((props) => {
        return [];
    }
    ))(Component);
