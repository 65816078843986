import { createStore, combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'

import 'firebase/auth'
import 'firebase/firestore'

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
});

const store = createStore(
    rootReducer,
    /* initialState= */
    {}
);

export default store;
