import React from 'react';

import PathPage from './pathpage.js';

import { StationMap } from '../data/data.js';

import { connect } from 'react-redux'
import { compose } from 'redux'

const mapStateToProps = state => { return {} }
const mapDispatchToProps = dispatch => { return {} }

const Component = () => {

    const pathResult = {
        "path": [
            {
                "f": 1131225,
                "t": 1131201,
                "w": 1
            },
            {
                "f": 1131201,
                "t": 1131202
            },
            {
                "f": 2800107,
                "t": 2800106
            }
        ],
        "poi": 418,
        "t": 7.650215306854468
    };
    const homeStation = StationMap[1131225];
    const workStation = StationMap[1131201];
    return (
        <div>
            {/* <PathView pathResult={pathResult} workStation={workStation} /> */}

            <PathPage key={1} pageIdx={1} fromStation={homeStation} toStation={workStation} pathResult={pathResult} />
            {/* <PathView path={pathResult.path[0]} workStation={workStation} />
            <PathView path={pathResult.path[1]} workStation={workStation} />
            <PathView path={pathResult.path[2]} workStation={workStation} /> */}
        </div>
    );
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps)
)(Component);
