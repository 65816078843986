import React from 'react';

import { fade, makeStyles } from '@material-ui/core/styles';

import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { StationsDB } from '../data/stations.js';

import { connect } from 'react-redux'
import { compose } from 'redux'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    contents: {
        margin: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
    },
    textField: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },
    stationName: {
        // textDecorationStyle: 'bold',
        // fontWeight: 'bold',
        marginRight: theme.spacing(0.2),
    },
    lineName: {
        // textDecorationStyle: 'bold',
        // fontWeight: 'bold',
        fontSize: '0.8em',
        color: '#888888',
    },
    searchField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.8),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.9),
        },
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        // [theme.breakpoints.up('sm')]: {
        //     marginLeft: theme.spacing(3),
        //     width: 'auto',
        // },
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
}));

const mapStateToProps = state => {
    return {
        // // uid: state.firebase.auth.uid,
        // suggestedStations: state.firestore.ordered.suggestedStations
        //     ? state.firestore.ordered.suggestedStations
        //     : [],
    };
}
const mapDispatchToProps = dispatch => {
    return {
        // doLogout: bindLogout(dispatch),
    }
}

const Component = ({
    onSet,
    title,
    currentStation
}) => {
    const classes = useStyles();

    return (
        <Autocomplete
            className={classes.search}
            options={StationsDB}
            getOptionLabel={station => station.n + " （" + station.l + "）"}
            renderOption={station => (
                <React.Fragment>
                    <span className={classes.stationName}>{station.n}</span>

                    <span className={classes.lineName}>{station.l}</span>
                </React.Fragment>
            )}
            style={{ width: 300 }}
            disableOpenOnFocus={true}
            filterOptions={
                createFilterOptions(
                    {
                        matchFrom: 'start',
                        stringify: station => station.n + "駅",
                    }
                )
            }
            forcePopupIcon={false}
            defaultValue={currentStation}
            onSubmitCapture={
                e => console.log(e)
            }
            onChange={
                (e, newValue) => { onSet(newValue) }
            }
            renderInput={params => (
                <TextField {...params} label={title} variant="outlined" fullWidth />
            )}
        />
    )
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps))(Component);
