import React, { useState, useEffect, useRef } from 'react';

import PathPage from './pathpage';

import { connect } from 'react-redux'
import { compose } from 'redux'

import { makeStyles, Typography } from '@material-ui/core';

import { firestoreConnect } from 'react-redux-firebase'
import firebase from '../firebase/';
import { TwitterFollowButton } from 'react-twitter-embed';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1),
    },
    contents: {
        margin: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
    },
    zeroState: {
        margin: theme.spacing(1),
    },
    loadingAnimationContainer: {
        position: 'relative',
    },
    loadingAnimationLayer: {
        position: 'relative',
    },
    drawUnit: {
        display: "inline-block",
        width: "5em",
    },
}));

const mapStateToProps = state => {
    return {
        pathsResults: state.firestore.ordered.pathsResult,
    };
}
const mapDispatchToProps = dispatch => {
    return {
        // doLogout: bindLogout(dispatch),
    }
}

function zeroStatePage(classes) {
    return (
        <div>
            <Typography variant="body1" className={classes.zeroState}>
                朝誰もが思う「もし会社で降りずにこのまま乗っていたらどこに行けるんだろう？」に答える乗り過ごし検索エンジンです。<br />
                やる気の出ない朝の通勤にご利用ください。<br />
                例：
                <a
                    href="/1131225/1132103"
                    onClick={e => {
                        // console.log("clicke!");
                        firebase.analytics().logEvent("click_example_search");
                    }}
                >
                    <span role="img" aria-label="家">🏠</span>西国分寺→<span role="img" aria-label="会社">🏢</span>渋谷
                </a>
            </Typography >
            <div className={classes.zeroState}>
                <TwitterFollowButton
                    screenName={'kenkawakenkenke'}
                />
            </div>
        </div >
    );
}

function encodeProps(fromStation, toStation) {
    return (fromStation ? fromStation.i : '?') + "_" + (toStation ? toStation.i : "?");
}

function fakeAnimationFrame(frame) {
    setTimeout(() => {
        if (frame()) {
            setTimeout(() => fakeAnimationFrame(frame), 100);
        }
    }, 100);
}
function doFakeLoadAnimation(finishedCallback, animationStateCallback, maxState, durationMs) {
    const tStart = Date.now();
    const animationFrame = () => {
        const state = Math.floor(maxState * (Date.now() - tStart) / durationMs);
        if (state >= maxState) {
            finishedCallback();
            return false;
        }
        animationStateCallback(state);
        return true;
    };
    fakeAnimationFrame(animationFrame);
}

const Component = ({
    fromStation,
    toStation,
    pathsResults
}) => {
    const classes = useStyles();

    const isMounted = useRef(false);
    const [prevLoadCache, setPrevLoadCache] = useState("");
    const [isInFakeLoad, setIsInFakeLoad] = useState(false);
    const [fakeLoadAnimationState, setFakeLoadAnimationState] = useState(0);

    const safeSetFakeLoadAnimationState = (v) => {
        if (!isMounted.current) {
            return;
        }
        setFakeLoadAnimationState(v);
    }
    const safeSetIsInFakeLoad = (v) => {
        if (!isMounted.current) {
            return;
        }
        setIsInFakeLoad(v);
    }

    const currentLoad = encodeProps(fromStation, toStation);
    const newLoad = prevLoadCache !== currentLoad;

    if (newLoad) {
        setPrevLoadCache(currentLoad);
        if (fromStation && toStation && !isInFakeLoad) {
            setIsInFakeLoad(true);
            setFakeLoadAnimationState(0);
        }
    }
    useEffect(() => {
        if (!isInFakeLoad) {
            return;
        }
        doFakeLoadAnimation(
            () => safeSetIsInFakeLoad(false),
            safeSetFakeLoadAnimationState,
            3,
            1200);
    }, [isInFakeLoad]);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, []);

    const isLoading = !pathsResults || pathsResults.length === 0;
    const pathsResult = !isLoading ? pathsResults[0] : {};

    var content;
    if (!fromStation || !toStation) {
        content = zeroStatePage(classes);
    }
    else if (isLoading || isInFakeLoad) {
        content = (<div>
            <div className={classes.loadingAnimationContainer}>
                <div className={classes.loadingAnimationLayer}>
                    <span className={classes.drawUnit} role="img" aria-label="家">🏠</span>
                    <span className={classes.drawUnit} role="img" aria-label="会社">🏢</span>
                    <span className={classes.drawUnit} role="img" aria-label="木">🌴</span>
                </div>
                <div className={classes.loadingAnimationLayer}>
                    {fakeLoadAnimationState >= 2 && <span className={classes.drawUnit}></span>}
                    {fakeLoadAnimationState >= 1 && <span className={classes.drawUnit}></span>}
                    {fakeLoadAnimationState >= 0 && <span className={classes.drawUnit} role="img" aria-label="電車">🚃</span>}
                </div>
            </div>
        </div >);
    }
    else if (!pathsResult.pois) {
        content = "経路が見つかりません。遠すぎるかも？";
    } else {
        content = pathsResult.pois.map((path, i) =>
            (<PathPage key={i} pageIdx={i} fromStation={fromStation} toStation={toStation} pathResult={path} />));
    }

    return (
        <div className={classes.root}>
            {content}
        </div>
    )
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps),
    firestoreConnect((props) => {
        if (!props.fromStation) return [];
        if (!props.toStation) return [];

        const id = props.fromStation.i + "_" + props.toStation.i;
        return [
            {
                collection: 'paths',
                doc: id,
                storeAs: 'pathsResult',
            }
        ]
    }
    ))(Component);
